@import "../../base/variables";

.page-error{
    background: url('../../../img/error-bg.png') no-repeat center center;
    width: 100%;
    height: 610px;
}
.four-img{
    padding: 220px 445px 0px; 
}
.five-img {
    padding: 220px 455px 0px;
}
.return{
    display: block;
    width: 150px;
	height: 42px;
	border-radius: 21px;
    border: solid 1px $color-skin-base;
    margin: 50px auto;
    color: $color-skin-base;
    font-size: 16px;
    padding-top: 8px;
}