//主体颜色-蓝紫
$color-skin-base: #5e5ecb !default; 
$color-skin-dark: #42428e !default;
$color-skin-light: #9a9adc !default;
$color-skin-lighter: #e5e5f7 !default;


//字体颜色
$font: #333 !default;
$font-base: #666 !default;
$font-light: #999 !default;
$font-lighter: #fff !default;
$font-serial: #f5f5f5 !default;
$font-error: #db0000 !default;


//边框颜色
$border: #333 !default;
$border-seat: #fff !default;
$border-light: #ccc !default;
$border-lighter: #f5f5f5 !default;
$border-gift: #db0000 !default;


//背景颜色
$bg: #fff !default;
$bg-light: #ddd !default;
$bg-dark: #38383c !default;
$bg-cart: #db0000 !default;
$bg-line: #999 !default;
$bg-indicators: #ccc !default;
$bg-indicators-two: #808080 !default;


//功能颜色
$body-bg: #f5f5f5 !default; 
$text-color: $font !default;
$text-price: $font-error !default;
$text-price-minus: #01ae42 !default;
$divider-color: #ddd !default;
$icon-success: #00db67 !default;
$icon-fail: $font-error !default;

$header-nav-font: #ddd !default;
$header-nav-horn: #FFFF00 !default;
$header-menu-font: #38383c !default;

$suspension-font: #ddd !default;


//链接
$link-color:           $font !default;
$link-hover-color:     $color-skin-base !default;

@mixin link-colors($normal, $hover, $visited) {
    color: $normal;
    &:hover,
    &:focus{ color: $hover; text-decoration: none }
}

